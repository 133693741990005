import { type FC } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import {
  ChubbsPlaceholderBackground,
  type ChubbsPlaceholderType,
} from '@/components/ui/ChubsPlaceholder/chubbsPlaceholderType';
import {
  CHUBS_PLACEHOLDER_ICON_MAP, CHUBS_PLACEHOLDER_PROPS_MAP,
} from '@/components/ui/ChubsPlaceholder/constants';
import styles from './ChubbsPlaceholder.module.scss';

type Props = {
  chubbsType: ChubbsPlaceholderType;
  textPrimary: string;
  textSecondary?: string;
  backgroundColor?: ChubbsPlaceholderBackground;
  className?: string;
};

const backgroundToStylesMapper = {
  [ChubbsPlaceholderBackground.PurpleSecondary]: styles.accentPurpleSecondary,
  [ChubbsPlaceholderBackground.Info]: styles.info,
  [ChubbsPlaceholderBackground.Neutral]: styles.neutral,
  [ChubbsPlaceholderBackground.BrandSecondary]: styles.brandSecondary,
  [ChubbsPlaceholderBackground.WarningSecondary]: styles.warningSecondary,
};

export const ChubbsPlaceholder: FC<Props> = ({
  chubbsType,
  textPrimary,
  textSecondary,
  backgroundColor = ChubbsPlaceholderBackground.Neutral,
  className,
}) => {
  const Chubbs = CHUBS_PLACEHOLDER_ICON_MAP[chubbsType];
  const chubbsSize = CHUBS_PLACEHOLDER_PROPS_MAP[chubbsType];

  return (
    <div className={className}>
      <div
        data-qa='chubbs-placeholder'
        className={cn(
          styles.chubbsContainer,
          backgroundToStylesMapper[backgroundColor],
        )}
      >
        <Chubbs {...chubbsSize} />
      </div>

      <div data-qa='empty-chat-text'>
        <h2 className={cn(typography.platformH2, 'text-center c-text-platform-default', 'mb-8')}>
          {textPrimary}
        </h2>

        {textSecondary && (
          <p className={cn(typography.platformTextSecondary, 'text-center c-text-platform-tertiary')}>
            {textSecondary}
          </p>
        )}
      </div>
    </div>
  );
};

import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import {
  ChubbsWrapper,
} from '@/components/ui/Chubbs/ChubbsWrapper';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsSendMessageLight: FC<ChubbsProps> = memo((props) => (
  <ChubbsWrapper initialHeight={195} initialWidth={124} {...props}>
    <g clipPath="url(#clip0_19425_3752)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.53 37.27C49.64 37.08 52.87 35.39 53.31 38.91C53.51 40.52 51.78 43.06 51.42 42.68C51.42 42.68 49.24 37.73 49.53 37.27Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M49.53 37.27C49.64 37.08 52.87 35.39 53.31 38.91C53.51 40.52 51.78 43.06 51.42 42.68C51.42 42.68 49.24 37.73 49.53 37.27Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.8799 46.6001C78.0999 51.4601 64.5799 64.4901 63.5499 65.5201C63.5499 65.5201 58.2099 55.8501 58.2099 55.4201C58.2099 54.3501 57.6199 55.0601 58.8899 50.2601C59.2066 49.207 59.1954 48.0824 58.8578 47.0357C58.5203 45.989 57.8723 45.0698 56.9999 44.4001C54.3399 42.0601 51.7499 43.2001 52.9999 46.3401C53.491 47.2539 54.1106 48.0925 54.8399 48.8301V51.6101C35.5099 45.4801 43.1499 50.0401 44.5399 54.7501C46.5399 61.4301 51.6299 71.9201 53.1499 75.8201C55.4899 81.8201 60.8699 81.4101 68.5199 77.1801C72.3999 75.0301 71.5199 75.1801 76.4699 71.4701C81.5799 67.6001 84.4699 55.9501 82.9899 49.7201C82.9199 49.4901 82.8799 46.6001 82.8799 46.6001Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M82.8799 46.6001C78.0999 51.4601 64.5799 64.4901 63.5499 65.5201C63.5499 65.5201 58.2099 55.8501 58.2099 55.4201C58.2099 54.3501 57.6199 55.0601 58.8899 50.2601C59.2066 49.207 59.1954 48.0824 58.8578 47.0357C58.5203 45.989 57.8723 45.0698 56.9999 44.4001C54.3399 42.0601 51.7499 43.2001 52.9999 46.3401C53.491 47.2539 54.1106 48.0925 54.8399 48.8301V51.6101C35.5099 45.4801 43.1499 50.0401 44.5399 54.7501C46.5399 61.4301 51.6299 71.9201 53.1499 75.8201C55.4899 81.8201 60.8699 81.4101 68.5199 77.1801C72.3999 75.0301 71.5199 75.1801 76.4699 71.4701C81.5799 67.6001 84.4699 55.9501 82.9899 49.7201C82.9199 49.4901 82.8799 46.6001 82.8799 46.6001Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M46.3858 35.1128L39.2043 38.1567C37.9076 38.7063 37.302 40.203 37.8516 41.4997L44.517 57.2254C45.0666 58.522 46.5633 59.1276 47.86 58.5781L55.0415 55.5341C56.3382 54.9845 56.9438 53.4878 56.3942 52.1912L49.7287 36.4654C49.1791 35.1688 47.6824 34.5632 46.3858 35.1128Z"
        fill="#2A2F3C"
      />
      <path
        d="M46.3858 35.1128L39.2043 38.1567C37.9076 38.7063 37.302 40.203 37.8516 41.4997L44.517 57.2254C45.0666 58.522 46.5633 59.1276 47.86 58.5781L55.0415 55.5341C56.3382 54.9845 56.9438 53.4878 56.3942 52.1912L49.7287 36.4654C49.1791 35.1688 47.6824 34.5632 46.3858 35.1128Z"
        stroke="#22262F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.5801 65.5102L65.6501 70.3802C65.0661 68.8613 64.8171 67.2342 64.9201 65.6102C64.9201 65.6102 65.2001 64.3602 64.9901 64.3902L63.5801 65.5102Z"
        fill="#2A2F3C"
      />
      <path
        d="M63.5801 65.5102L65.6501 70.3802C65.0661 68.8613 64.8171 67.2342 64.9201 65.6102C64.9201 65.6102 65.2001 64.3602 64.9901 64.3902L63.5801 65.5102Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.89 47.2202L79.52 50.3802C77.52 59.6502 74.52 65.0902 72.96 74.1802C74.96 72.5202 76.41 71.1802 78.41 69.4802L82.89 47.2202Z"
        fill="#2A2F3C"
      />
      <path
        d="M82.89 47.2202L79.52 50.3802C77.52 59.6502 74.52 65.0902 72.96 74.1802C74.96 72.5202 76.41 71.1802 78.41 69.4802L82.89 47.2202Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.3203 177.69H98.3203C98.4877 177.697 98.6462 177.767 98.7647 177.886C98.8832 178.004 98.953 178.163 98.9603 178.33L99.9603 192.49H66.3403C65.7103 186.56 70.0503 184.77 75.1703 182.22C77.1703 180.75 77.3203 177.69 77.3203 177.69Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M77.3203 177.69H98.3203C98.4877 177.697 98.6462 177.767 98.7647 177.886C98.8832 178.004 98.953 178.163 98.9603 178.33L99.9603 192.49H66.3403C65.7103 186.56 70.0503 184.77 75.1703 182.22C77.1703 180.75 77.3203 177.69 77.3203 177.69Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.32 190.23H66.3402C65.9867 190.23 65.7002 190.517 65.7002 190.87V193.9C65.7002 194.253 65.9867 194.54 66.3402 194.54H100.32C100.674 194.54 100.96 194.253 100.96 193.9V190.87C100.96 190.517 100.674 190.23 100.32 190.23Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M100.32 190.23H66.3402C65.9867 190.23 65.7002 190.517 65.7002 190.87V193.9C65.7002 194.253 65.9867 194.54 66.3402 194.54H100.32C100.674 194.54 100.96 194.253 100.96 193.9V190.87C100.96 190.517 100.674 190.23 100.32 190.23Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98 177.69H119C119.167 177.697 119.326 177.767 119.444 177.886C119.563 178.004 119.633 178.163 119.64 178.33L120.64 192.49H87C86.37 186.56 90.71 184.77 95.83 182.22C97.85 180.75 98 177.69 98 177.69Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M98 177.69H119C119.167 177.697 119.326 177.767 119.444 177.886C119.563 178.004 119.633 178.163 119.64 178.33L120.64 192.49H87C86.37 186.56 90.71 184.77 95.83 182.22C97.85 180.75 98 177.69 98 177.69Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.01 190.23H87.0199C86.6664 190.23 86.3799 190.517 86.3799 190.87V193.9C86.3799 194.253 86.6664 194.54 87.0199 194.54H121.01C121.363 194.54 121.65 194.253 121.65 193.9V190.87C121.65 190.517 121.363 190.23 121.01 190.23Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M121.01 190.23H87.0199C86.6664 190.23 86.3799 190.517 86.3799 190.87V193.9C86.3799 194.253 86.6664 194.54 87.0199 194.54H121.01C121.363 194.54 121.65 194.253 121.65 193.9V190.87C121.65 190.517 121.363 190.23 121.01 190.23Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.2402 17.0698C83.2402 25.6298 83.2402 25.4798 83.2402 34.0698C83.2402 56.6698 72.9602 61.4398 72.3202 84.6898C72.2002 88.9398 76.0002 88.4098 76.6502 94.1198C81.4502 95.2898 94.2902 90.5998 99.1002 91.7698C103.88 90.5998 116.52 100.1 121.31 98.9298C129.72 61.9898 111.97 54.7998 112 35.5698V17.0998C112 -1.8702 83.2602 -1.22021 83.2402 17.0698Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M83.2402 17.0698C83.2402 25.6298 83.2402 25.4798 83.2402 34.0698C83.2402 56.6698 72.9602 61.4398 72.3202 84.6898C72.2002 88.9398 76.0002 88.4098 76.6502 94.1198C81.4502 95.2898 94.2902 90.5998 99.1002 91.7698C103.88 90.5998 116.52 100.1 121.31 98.9298C129.72 61.9898 111.97 54.7998 112 35.5698V17.0998C112 -1.8702 83.2602 -1.22021 83.2402 17.0698Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.2403 17.0702C83.2403 25.6302 83.2403 25.4802 83.2403 34.0702C83.2481 36.5829 83.1078 39.0939 82.8203 41.5902H112.59C112.201 39.6138 112.003 37.6045 112 35.5902V17.1202C112 -1.86983 83.2603 -1.21984 83.2403 17.0702Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M83.2403 17.0702C83.2403 25.6302 83.2403 25.4802 83.2403 34.0702C83.2481 36.5829 83.1078 39.0939 82.8203 41.5902H112.59C112.201 39.6138 112.003 37.6045 112 35.5902V17.1202C112 -1.86983 83.2603 -1.21984 83.2403 17.0702Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.93 15.5602C110.51 -1.3098 84.8301 -0.769801 83.3101 15.5602H111.93Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M111.93 15.5602C110.51 -1.3098 84.8301 -0.769801 83.3101 15.5602H111.93Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.25 84C123.25 84.21 123.25 84.42 123.25 84.62C123.44 94.29 120.32 99.62 120.34 108.45C120.4 127.61 120.92 176.84 120.86 177.83H76.1498C76.1498 177.83 75.7598 127.73 76.0298 108.95C76.1798 98.95 73.2798 96.08 72.1998 84.73C72.1847 84.4869 72.1847 84.2431 72.1998 84H123.25Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M123.25 84C123.25 84.21 123.25 84.42 123.25 84.62C123.44 94.29 120.32 99.62 120.34 108.45C120.4 127.61 120.92 176.84 120.86 177.83H76.1498C76.1498 177.83 75.7598 127.73 76.0298 108.95C76.1798 98.95 73.2798 96.08 72.1998 84.73C72.1847 84.4869 72.1847 84.2431 72.1998 84H123.25Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.5099 27.0001C84.843 27.0021 85.1692 26.905 85.447 26.7211C85.7248 26.5373 85.9417 26.275 86.0701 25.9676C86.1984 25.6603 86.2325 25.3216 86.168 24.9948C86.1035 24.668 85.9433 24.3677 85.7078 24.1322C85.4722 23.8966 85.172 23.7364 84.8452 23.6719C84.5183 23.6074 84.1797 23.6415 83.8723 23.7699C83.5649 23.8983 83.3027 24.1152 83.1188 24.393C82.935 24.6708 82.8379 24.997 82.8399 25.3301C82.8399 25.773 83.0158 26.1978 83.329 26.511C83.6422 26.8242 84.067 27.0001 84.5099 27.0001Z"
        fill="white"
      />
      <path
        d="M84.5099 27.0001C84.843 27.0021 85.1692 26.905 85.447 26.7211C85.7248 26.5373 85.9417 26.275 86.0701 25.9676C86.1984 25.6603 86.2325 25.3216 86.168 24.9948C86.1035 24.668 85.9433 24.3677 85.7078 24.1322C85.4722 23.8966 85.172 23.7364 84.8452 23.6719C84.5183 23.6074 84.1797 23.6415 83.8723 23.7699C83.5649 23.8983 83.3027 24.1152 83.1188 24.393C82.935 24.6708 82.8379 24.997 82.8399 25.3301C82.8399 25.773 83.0158 26.1978 83.329 26.511C83.6422 26.8242 84.067 27.0001 84.5099 27.0001Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.5401 23.69C98.2074 23.688 97.8816 23.7848 97.604 23.9682C97.3264 24.1516 97.1096 24.4133 96.9809 24.7201C96.8522 25.0269 96.8175 25.365 96.8812 25.6916C96.9449 26.0181 97.1041 26.3184 97.3386 26.5543C97.5732 26.7903 97.8725 26.9513 98.1986 27.0169C98.5248 27.0826 98.8631 27.0499 99.1707 26.923C99.4782 26.7962 99.7412 26.5808 99.9263 26.3044C100.111 26.0279 100.21 25.7027 100.21 25.37C100.211 25.1498 100.169 24.9316 100.086 24.7278C100.003 24.524 99.8797 24.3388 99.7245 24.1826C99.5693 24.0265 99.3848 23.9026 99.1815 23.818C98.9782 23.7335 98.7602 23.69 98.5401 23.69Z"
        fill="white"
      />
      <path
        d="M98.5401 23.69C98.2074 23.688 97.8816 23.7848 97.604 23.9682C97.3264 24.1516 97.1096 24.4133 96.9809 24.7201C96.8522 25.0269 96.8175 25.365 96.8812 25.6916C96.9449 26.0181 97.1041 26.3184 97.3386 26.5543C97.5732 26.7903 97.8725 26.9513 98.1986 27.0169C98.5248 27.0826 98.8631 27.0499 99.1707 26.923C99.4782 26.7962 99.7412 26.5808 99.9263 26.3044C100.111 26.0279 100.21 25.7027 100.21 25.37C100.211 25.1498 100.169 24.9316 100.086 24.7278C100.003 24.524 99.8797 24.3388 99.7245 24.1826C99.5693 24.0265 99.3848 23.9026 99.1815 23.818C98.9782 23.7335 98.7602 23.69 98.5401 23.69V23.69Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.8101 109.19C97.8101 105.1 91.1001 105.49 88.6401 99.6699"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.14 18.8799C84.4811 18.7884 83.8099 18.8927 83.21 19.1799"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M97.8301 18.8799C98.4889 18.7866 99.1606 18.891 99.7601 19.1799"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.73 39H83.2301C82.693 39 82.1779 39.2133 81.7982 39.5931C81.4184 39.9729 81.2051 40.4879 81.2051 41.025C81.2051 41.5621 81.4184 42.0771 81.7982 42.4569C82.1779 42.8367 82.693 43.05 83.2301 43.05H111.73C112.267 43.05 112.782 42.8367 113.162 42.4569C113.542 42.0771 113.755 41.5621 113.755 41.025C113.755 40.4879 113.542 39.9729 113.162 39.5931C112.782 39.2133 112.267 39 111.73 39Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M111.73 39H83.2301C82.693 39 82.1779 39.2133 81.7982 39.5931C81.4184 39.9729 81.2051 40.4879 81.2051 41.025C81.2051 41.5621 81.4184 42.0771 81.7982 42.4569C82.1779 42.8367 82.693 43.05 83.2301 43.05H111.73C112.267 43.05 112.782 42.8367 113.162 42.4569C113.542 42.0771 113.755 41.5621 113.755 41.025C113.755 40.4879 113.542 39.9729 113.162 39.5931C112.782 39.2133 112.267 39 111.73 39V39Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M112 15.4102H68.1499"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.8299 42.9199L82.3999 44.6799C87.9567 43.8011 93.5592 43.2402 99.1799 42.9999L82.8299 42.9199Z"
        fill="#2A2F3C"
      />
      <path
        d="M82.8299 42.9199L82.3999 44.6799C87.9567 43.8011 93.5592 43.2402 99.1799 42.9999L82.8299 42.9199Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.1499 177.84L76.71 179.6C82.2698 178.715 87.8755 178.147 93.5 177.9L77.1499 177.84Z"
        fill="#2A2F3C"
      />
      <path
        d="M77.1499 177.84L76.71 179.6C82.2698 178.715 87.8755 178.147 93.5 177.9L77.1499 177.84Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.1099 177.84L97.6699 179.6C103.23 178.714 108.835 178.146 114.46 177.9L98.1099 177.84Z"
        fill="#2A2F3C"
      />
      <path
        d="M98.1099 177.84L97.6699 179.6C103.23 178.714 108.835 178.146 114.46 177.9L98.1099 177.84Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.68 15.3398L83.25 17.1098C88.7965 16.2254 94.3889 15.6579 100 15.4098L83.68 15.3398Z"
        fill="#2A2F3C"
      />
      <path
        d="M83.68 15.3398L83.25 17.1098C88.7965 16.2254 94.3889 15.6579 100 15.4098L83.68 15.3398Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.7699 25.5301C83.7446 25.5286 83.7192 25.5325 83.6955 25.5416C83.6718 25.5506 83.6503 25.5646 83.6323 25.5825C83.6144 25.6005 83.6004 25.622 83.5914 25.6457C83.5824 25.6694 83.5784 25.6948 83.5799 25.7201C83.5799 25.7705 83.5999 25.8188 83.6355 25.8544C83.6712 25.8901 83.7195 25.9101 83.7699 25.9101C83.8195 25.9077 83.8664 25.8869 83.9015 25.8517C83.9367 25.8166 83.9575 25.7697 83.9599 25.7201C83.9599 25.6697 83.9399 25.6214 83.9042 25.5857C83.8686 25.5501 83.8203 25.5301 83.7699 25.5301Z"
        fill="#2A2F3C"
      />
      <path
        d="M83.7699 25.5301C83.7446 25.5286 83.7192 25.5325 83.6955 25.5416C83.6718 25.5506 83.6503 25.5646 83.6323 25.5825C83.6144 25.6005 83.6004 25.622 83.5914 25.6457C83.5824 25.6694 83.5784 25.6948 83.5799 25.7201C83.5799 25.7705 83.5999 25.8188 83.6355 25.8544C83.6712 25.8901 83.7195 25.9101 83.7699 25.9101C83.8195 25.9077 83.8664 25.8869 83.9015 25.8517C83.9367 25.8166 83.9575 25.7697 83.9599 25.7201C83.9599 25.6697 83.9399 25.6214 83.9042 25.5857C83.8686 25.5501 83.8203 25.5301 83.7699 25.5301V25.5301Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.6002 25.92C97.6255 25.9215 97.6508 25.9176 97.6745 25.9085C97.6983 25.8995 97.7198 25.8856 97.7377 25.8676C97.7557 25.8497 97.7696 25.8281 97.7786 25.8044C97.7877 25.7807 97.7916 25.7554 97.7901 25.73C97.7901 25.6797 97.7701 25.6313 97.7345 25.5957C97.6989 25.5601 97.6505 25.54 97.6002 25.54C97.5506 25.5425 97.5036 25.5633 97.4685 25.5984C97.4334 25.6335 97.4126 25.6804 97.4102 25.73C97.4102 25.7804 97.4302 25.8288 97.4658 25.8644C97.5014 25.9 97.5498 25.92 97.6002 25.92Z"
        fill="#2A2F3C"
      />
      <path
        d="M97.6002 25.92C97.6255 25.9215 97.6508 25.9176 97.6745 25.9085C97.6983 25.8995 97.7198 25.8856 97.7377 25.8676C97.7557 25.8497 97.7696 25.8281 97.7786 25.8044C97.7877 25.7807 97.7916 25.7554 97.7901 25.73C97.7901 25.6797 97.7701 25.6313 97.7345 25.5957C97.6989 25.5601 97.6505 25.54 97.6002 25.54C97.5506 25.5425 97.5036 25.5633 97.4685 25.5984C97.4334 25.6335 97.4126 25.6804 97.4102 25.73C97.4102 25.7804 97.4302 25.8288 97.4658 25.8644C97.5014 25.9 97.5498 25.92 97.6002 25.92V25.92Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.7803 177.87V109.07"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M88.9297 21.5698H111.99"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.3799 21.5698H103.1V23.0498C103.1 24.4714 102.535 25.8347 101.53 26.8399C100.525 27.8451 99.1614 28.4098 97.7399 28.4098C96.3183 28.4098 94.955 27.8451 93.9498 26.8399C92.9446 25.8347 92.3799 24.4714 92.3799 23.0498V21.5698Z"
        fill="#2A2F3C"
      />
      <path
        d="M92.3799 21.5698H103.1V23.0498C103.1 24.4714 102.535 25.8347 101.53 26.8399C100.525 27.8451 99.1614 28.4098 97.7399 28.4098C96.3183 28.4098 94.955 27.8451 93.9498 26.8399C92.9446 25.8347 92.3799 24.4714 92.3799 23.0498V21.5698Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.6899 21.5698H90.4099V23.0498C90.4099 24.4714 89.8452 25.8347 88.84 26.8399C87.8348 27.8451 86.4715 28.4098 85.0499 28.4098C83.6284 28.4098 82.265 27.8451 81.2598 26.8399C80.2546 25.8347 79.6899 24.4714 79.6899 23.0498V21.5698Z"
        fill="#2A2F3C"
      />
      <path
        d="M79.6899 21.5698H90.4099V23.0498C90.4099 24.4714 89.8452 25.8347 88.84 26.8399C87.8348 27.8451 86.4715 28.4098 85.0499 28.4098C83.6284 28.4098 82.265 27.8451 81.2598 26.8399C80.2546 25.8347 79.6899 24.4714 79.6899 23.0498V21.5698Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M100.27 32.5001C100.598 32.3301 100.889 32.0967 101.125 31.8134C101.362 31.5302 101.541 31.2028 101.65 30.8501"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M100.67 30.1899C100.968 30.4373 101.317 30.6172 101.691 30.7173C102.066 30.8174 102.458 30.8353 102.84 30.7699"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.7399 56.6998C63.0299 58.8998 67.5299 60.3198 72.2699 62.4598C75.679 64.0452 79.2592 65.233 82.9399 65.9998C86.6099 66.6398 88.9399 66.9098 91.8299 65.8798C95.9899 64.3998 111.67 57.4698 112.72 55.5998C115.54 50.5998 112.34 45.2798 106.5 45.2098L87.2499 54.2098L64.6099 45.7198C61.7599 40.2198 57.6099 43.1798 57.4099 42.9198L52.2199 40.8598C50.7199 40.2598 47.3099 39.2898 46.8699 41.7398C46.5199 43.6598 52.0599 45.2898 52.0599 45.2898C48.3599 46.1098 51.0599 49.2898 51.0599 49.2898C49.1699 51.0098 50.0599 52.6098 52.7599 54.1198C54.4999 55.1198 57.8299 56.2298 58.7599 56.7198"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M106.5 45.2098L87.2499 54.2098L64.6099 45.7198C61.7599 40.2198 57.6099 43.1798 57.4099 42.9198L52.2199 40.8598C50.7199 40.2598 47.3099 39.2898 46.8699 41.7398C46.5199 43.6598 52.0599 45.2898 52.0599 45.2898C48.3599 46.1098 51.0599 49.2898 51.0599 49.2898C49.1699 51.0098 50.0599 52.6098 52.7599 54.1198C54.4999 55.1198 57.8299 56.2298 58.7599 56.7198C63.0499 58.9198 67.5499 60.3398 72.2899 62.4798C75.6935 64.0569 79.2669 65.238 82.9399 65.9998C86.6099 66.6398 89.4299 66.7398 92.2999 65.7098C96.4499 64.2298 111.67 57.4698 112.72 55.5998"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M51.4502 45.0898L54.7202 46.1898"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.27 42.8301L59.61 43.6901"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.02 49.27L54.21 50.27"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9998 44.2398C39.0798 44.4498 45.6098 44.7798 43.6798 47.6598C43.8865 47.6954 44.0903 47.7455 44.2898 47.8098C44.6141 47.9264 44.893 48.1428 45.0866 48.4278C45.2802 48.7128 45.3785 49.0518 45.3674 49.3962C45.3562 49.7406 45.2363 50.0725 45.0247 50.3445C44.8132 50.6164 44.5209 50.8144 44.1898 50.9098C44.1898 50.9098 46.2798 50.6598 46.1898 52.3298C46.1298 54.2698 42.1198 54.0798 40.8198 53.6998C40.3949 53.6216 40.0088 53.4024 39.7239 53.0776C39.439 52.7527 39.272 52.3413 39.2498 51.9098C39.1698 51.0698 39.7598 51.9098 38.1898 50.6398C36.6198 49.3698 37.9698 47.7098 37.5198 47.2898C36.4098 46.2898 35.5198 43.9198 38.1998 43.2398C38.2598 43.2298 38.9998 44.2398 38.9998 44.2398Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M38.9998 44.2398C39.0798 44.4498 45.6098 44.7798 43.6798 47.6598C43.8865 47.6954 44.0903 47.7455 44.2898 47.8098C44.6141 47.9264 44.893 48.1428 45.0866 48.4278C45.2802 48.7128 45.3785 49.0518 45.3674 49.3962C45.3562 49.7406 45.2363 50.0725 45.0247 50.3445C44.8132 50.6164 44.5209 50.8144 44.1898 50.9098C44.1898 50.9098 46.2798 50.6598 46.1898 52.3298C46.1298 54.2698 42.1198 54.0798 40.8198 53.6998C40.3949 53.6216 40.0088 53.4024 39.7239 53.0776C39.439 52.7527 39.272 52.3413 39.2498 51.9098C39.1698 51.0698 39.7598 51.9098 38.1898 50.6398C36.6198 49.3698 37.9698 47.7098 37.5198 47.2898C36.4098 46.2898 35.5198 43.9198 38.1998 43.2398C38.2598 43.2298 38.9998 44.2398 38.9998 44.2398Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M37.52 47.2598C37.8655 47.4375 38.2316 47.5719 38.61 47.6598C40.21 48.0439 41.8731 48.0848 43.49 47.7798H43.61"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M39 51.2002C40.74 51.2002 43 51.4602 44.58 50.7602C44.6195 50.746 44.6566 50.7257 44.69 50.7002"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.9997 64.3898L75.4697 65.9298C79.2497 66.9298 83.8097 67.8298 87.7297 67.4298C90.8554 66.9967 93.8637 65.9464 96.5797 64.3398C97.7978 63.6089 99.0528 62.9413 100.34 62.3398C100.17 62.3398 92.5397 66.6199 86.8197 66.6199C85.0585 66.6032 83.3083 66.3406 81.6197 65.8398L75.9997 64.3898Z"
        fill="#2A2F3C"
      />
      <path
        d="M75.9997 64.3898L75.4697 65.9298C79.2497 66.9298 83.8097 67.8298 87.7297 67.4298C90.8554 66.9967 93.8637 65.9464 96.5797 64.3398C97.7978 63.6089 99.0528 62.9413 100.34 62.3398C100.17 62.3398 92.5397 66.6199 86.8197 66.6199C85.0585 66.6032 83.3083 66.3406 81.6197 65.8398L75.9997 64.3898Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56006 1.29004L32.9401 12.65L34.9801 5.89004L5.56006 1.29004Z"
        fill="#2A2F3C"
      />
      <path
        d="M5.56006 1.29004L32.9401 12.65L34.9801 5.89004L5.56006 1.29004Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5801 3.41992L32.4001 7.66992L33.0901 12.7199L11.5801 3.41992Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M11.5801 3.41992L32.4001 7.66992L33.0901 12.7199L11.5801 3.41992Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.55 12.84L32.4 7.66996L0.459961 0.459961L23.55 12.84Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M23.55 12.84L32.4 7.66996L0.459961 0.459961L23.55 12.84Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.74 6.02996L43.6 0.84996L0.459961 0.459961L34.74 6.02996Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M34.74 6.02996L43.6 0.84996L0.459961 0.459961L34.74 6.02996Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.7501 7.52002C40.4693 8.64383 42.8111 10.5207 44.5001 12.93C47.0001 16.5 47.8601 25.19 42.9801 27.12C39.4901 28.51 35.1901 23.39 37.0601 21.8C39.4201 19.8 42.9001 25.96 42.6301 32.93"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19425_3752">
        <rect width="124" height="195" fill="white" />
      </clipPath>
    </defs>

  </ChubbsWrapper>
));

import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import {
  ChubbsWrapper,
} from '@/components/ui/Chubbs/ChubbsWrapper';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsReadingDark: FC<ChubbsProps> = memo((props) => (
  <ChubbsWrapper initialHeight={157} initialWidth={92} {...props}>
    <g clipPath="url(#clip0_19425_3751)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2903 109.42C26.6592 109.67 26.1525 110.159 25.8808 110.781C25.6092 111.403 25.5946 112.107 25.8403 112.74L42.0003 154.08C42.1231 154.393 42.3064 154.679 42.5397 154.922C42.773 155.164 43.0517 155.358 43.36 155.493C43.6683 155.627 44.0001 155.7 44.3364 155.706C44.6728 155.713 45.0071 155.653 45.3203 155.53C45.6335 155.407 45.9195 155.224 46.1618 154.991C46.4042 154.757 46.5983 154.479 46.7329 154.17C46.8676 153.862 46.9402 153.53 46.9466 153.194C46.953 152.858 46.8931 152.523 46.7703 152.21L30.6403 110.88C30.5178 110.563 30.3335 110.273 30.0983 110.028C29.8631 109.783 29.5816 109.587 29.27 109.451C28.9585 109.315 28.6231 109.242 28.2833 109.237C27.9434 109.232 27.6059 109.294 27.2903 109.42Z"
        fill="#191A1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.66 109.42C24.2929 109.668 24.8019 110.156 25.0755 110.778C25.3491 111.401 25.3651 112.106 25.12 112.74L8.99995 154.08C8.75197 154.712 8.26288 155.22 7.64025 155.492C7.01763 155.764 6.31249 155.778 5.67995 155.53C5.04741 155.282 4.53928 154.793 4.26735 154.17C3.99543 153.547 3.98198 152.842 4.22995 152.21L20.37 110.88C20.6158 110.252 21.0991 109.747 21.715 109.474C22.331 109.201 23.0298 109.181 23.66 109.42Z"
        fill="#191A1F"
      />
      <path
        d="M27.2903 109.42C26.6592 109.67 26.1525 110.159 25.8808 110.781C25.6092 111.403 25.5946 112.107 25.8403 112.74L42.0003 154.08C42.1231 154.393 42.3064 154.679 42.5397 154.922C42.773 155.164 43.0517 155.358 43.36 155.493C43.6683 155.627 44.0001 155.7 44.3364 155.706C44.6728 155.713 45.0071 155.653 45.3203 155.53C45.6335 155.407 45.9195 155.224 46.1618 154.991C46.4042 154.757 46.5983 154.479 46.7329 154.17C46.8676 153.862 46.9402 153.53 46.9466 153.194C46.953 152.858 46.8931 152.523 46.7703 152.21L30.6403 110.88C30.5178 110.563 30.3335 110.273 30.0983 110.028C29.8631 109.783 29.5816 109.587 29.27 109.451C28.9585 109.315 28.6231 109.242 28.2833 109.237C27.9434 109.232 27.6059 109.294 27.2903 109.42V109.42Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M23.66 109.42C24.2929 109.668 24.8019 110.156 25.0755 110.778C25.3491 111.401 25.3651 112.106 25.12 112.74L8.99995 154.08C8.75197 154.712 8.26288 155.22 7.64025 155.492C7.01763 155.764 6.31249 155.778 5.67995 155.53C5.04741 155.282 4.53928 154.793 4.26735 154.17C3.99543 153.547 3.98198 152.842 4.22995 152.21L20.37 110.88C20.6158 110.252 21.0991 109.747 21.715 109.474C22.331 109.201 23.0298 109.181 23.66 109.42V109.42Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7602 106.66C25.0811 106.665 24.4315 106.938 23.9523 107.419C23.473 107.9 23.2028 108.551 23.2002 109.23V153.61C23.241 154.263 23.5289 154.875 24.0055 155.323C24.482 155.771 25.1113 156.02 25.7652 156.02C26.4191 156.02 27.0484 155.771 27.5249 155.323C28.0015 154.875 28.2894 154.263 28.3302 153.61V109.23C28.3276 108.549 28.056 107.897 27.5746 107.416C27.0932 106.934 26.441 106.663 25.7602 106.66Z"
        fill="#191A1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.4701 51.9001L53.0001 56.4201C53.0001 56.4201 59.6301 48.8401 59.7001 47.5901C59.8801 44.3501 59.2101 40.5901 60.2701 37.3401C62.0201 31.9901 67.2701 33.2601 68.5701 36.1201C68.5701 36.1201 60.0101 36.0501 70.5701 36.1201C71.0442 36.0955 71.5151 36.2097 71.9252 36.4487C72.3353 36.6877 72.6668 37.0411 72.8791 37.4657C73.0914 37.8902 73.1752 38.3675 73.1203 38.839C73.0655 39.3104 72.8743 39.7557 72.5701 40.1201C72.4601 40.2701 75.5701 41.2901 73.5701 44.4101C76.0901 47.2001 72.9301 48.8901 72.7101 49.1101C68.9001 52.7201 64.5101 60.6001 62.3101 64.1101C57.5301 71.8201 44.2101 64.4601 43.3101 63.6501C43.0001 63.4201 46.4701 51.9001 46.4701 51.9001Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M46.4701 51.9001L53.0001 56.4201C53.0001 56.4201 59.6301 48.8401 59.7001 47.5901C59.8801 44.3501 59.2101 40.5901 60.2701 37.3401C62.0201 31.9901 67.2701 33.2601 68.5701 36.1201C68.5701 36.1201 60.0101 36.0501 70.5701 36.1201C71.0442 36.0955 71.5151 36.2097 71.9252 36.4487C72.3353 36.6877 72.6668 37.0411 72.8791 37.4657C73.0914 37.8902 73.1752 38.3675 73.1203 38.839C73.0655 39.3104 72.8743 39.7557 72.5701 40.1201C72.4601 40.2701 75.5701 41.2901 73.5701 44.4101C76.0901 47.2001 72.9301 48.8901 72.7101 49.1101C68.9001 52.7201 64.5101 60.6001 62.3101 64.1101C57.5301 71.8201 44.2101 64.4601 43.3101 63.6501C43.0001 63.4201 46.4701 51.9001 46.4701 51.9001Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M65.7902 36.2402C62.7902 36.6002 62.0002 40.7902 65.9102 40.6102C67.4402 40.5402 69.4202 40.3802 69.2902 40.4102C68.8102 40.5102 62.7402 40.8302 64.5502 43.5202C65.6902 45.2002 70.4402 44.5202 70.2802 44.5202C69.2609 44.6187 68.2662 44.8927 67.3402 45.3302C64.5302 46.4502 65.2502 48.8202 68.0002 49.0602C68.7002 49.1202 69.7102 49.1502 70.4402 49.1502"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.9102 34.6001H65.7802V47.8901C65.7802 48.6416 65.4823 49.3624 64.9519 49.8948C64.4214 50.4271 63.7016 50.7275 62.9501 50.7301H55.7402C54.9887 50.7275 54.2689 50.4271 53.7384 49.8948C53.208 49.3624 52.9102 48.6416 52.9102 47.8901V34.6001Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M52.9102 34.6001H65.7802V47.8901C65.7802 48.6416 65.4823 49.3624 64.9519 49.8948C64.4214 50.4271 63.7016 50.7275 62.9501 50.7301H55.7402C54.9887 50.7275 54.2689 50.4271 53.7384 49.8948C53.208 49.3624 52.9102 48.6416 52.9102 47.8901V34.6001Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M56.4398 31.6601C54.0198 25.3601 58.5698 25.6601 58.5498 21.8701C58.5498 19.8701 56.8098 19.1601 56.6898 17.3501"
        stroke="#B5B6BA"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M59.26 33.0001C56.75 27.2601 59.04 27.7401 60.03 24.3101"
        stroke="#B5B6BA"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M25.7602 106.66C25.0811 106.665 24.4315 106.938 23.9523 107.419C23.473 107.9 23.2028 108.551 23.2002 109.23V153.61C23.241 154.263 23.5289 154.875 24.0055 155.323C24.482 155.771 25.1113 156.02 25.7652 156.02C26.4191 156.02 27.0484 155.771 27.5249 155.323C28.0015 154.875 28.2894 154.263 28.3302 153.61V109.23C28.3276 108.549 28.056 107.897 27.5746 107.416C27.0932 106.934 26.441 106.663 25.7602 106.66V106.66Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M41.9398 105.92H9.8298C6.91374 105.92 4.5498 108.284 4.5498 111.2C4.5498 114.116 6.91374 116.48 9.8298 116.48H41.9398C44.8559 116.48 47.2198 114.116 47.2198 111.2C47.2198 108.284 44.8559 105.92 41.9398 105.92Z"
        fill="#191A1F"
      />
      <path
        d="M41.9398 105.92H9.8298C6.91374 105.92 4.5498 108.284 4.5498 111.2C4.5498 114.116 6.91374 116.48 9.8298 116.48H41.9398C44.8559 116.48 47.2198 114.116 47.2198 111.2C47.2198 108.284 44.8559 105.92 41.9398 105.92Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.9999 139.12H58.9999C58.8317 139.125 58.6718 139.194 58.5529 139.313C58.4339 139.432 58.3649 139.592 58.3599 139.76L57.3599 153.92H90.9299C91.5599 147.99 87.2199 146.19 82.0999 143.65C80.1099 142.18 79.9999 139.12 79.9999 139.12Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M79.9999 139.12H58.9999C58.8317 139.125 58.6718 139.194 58.5529 139.313C58.4339 139.432 58.3649 139.592 58.3599 139.76L57.3599 153.92H90.9299C91.5599 147.99 87.2199 146.19 82.0999 143.65C80.1099 142.18 79.9999 139.12 79.9999 139.12Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.9401 151.65H56.9501C56.5966 151.65 56.3101 151.936 56.3101 152.29V155.32C56.3101 155.673 56.5966 155.96 56.9501 155.96H90.9401C91.2935 155.96 91.5801 155.673 91.5801 155.32V152.29C91.5801 151.936 91.2935 151.65 90.9401 151.65Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M90.9401 151.65H56.9501C56.5966 151.65 56.3101 151.936 56.3101 152.29V155.32C56.3101 155.673 56.5966 155.96 56.9501 155.96H90.9401C91.2935 155.96 91.5801 155.673 91.5801 155.32V152.29C91.5801 151.936 91.2935 151.65 90.9401 151.65Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.6601 139.42L80.1001 141.18C74.5402 140.295 68.9345 139.727 63.3101 139.48L79.6601 139.42Z"
        fill="#191A1F"
      />
      <path
        d="M79.6601 139.42L80.1001 141.18C74.5402 140.295 68.9345 139.727 63.3101 139.48L79.6601 139.42Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.0502 139.08H42.0502C41.8828 139.087 41.7242 139.157 41.6058 139.276C41.4873 139.394 41.4175 139.553 41.4102 139.72L40.4102 153.88H74.0002C74.6302 147.95 70.2902 146.15 65.1602 143.61C63.2102 142.14 63.0502 139.08 63.0502 139.08Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M63.0502 139.08H42.0502C41.8828 139.087 41.7242 139.157 41.6058 139.276C41.4873 139.394 41.4175 139.553 41.4102 139.72L40.4102 153.88H74.0002C74.6302 147.95 70.2902 146.15 65.1602 143.61C63.2102 142.14 63.0502 139.08 63.0502 139.08Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.0401 151.62H40.0502C39.6967 151.62 39.4102 151.907 39.4102 152.26V155.29C39.4102 155.644 39.6967 155.93 40.0502 155.93H74.0401C74.3936 155.93 74.6801 155.644 74.6801 155.29V152.26C74.6801 151.907 74.3936 151.62 74.0401 151.62Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M74.0401 151.62H40.0502C39.6967 151.62 39.4102 151.907 39.4102 152.26V155.29C39.4102 155.644 39.6967 155.93 40.0502 155.93H74.0401C74.3936 155.93 74.6801 155.644 74.6801 155.29V152.26C74.6801 151.907 74.3936 151.62 74.0401 151.62Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.0601 139.38L63.4901 141.15C58.0758 140.229 52.6078 139.658 47.1201 139.44L63.0601 139.38Z"
        fill="#191A1F"
      />
      <path
        d="M63.0601 139.38L63.4901 141.15C58.0758 140.229 52.6078 139.658 47.1201 139.44L63.0601 139.38Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.2201 14.7998C42.2201 23.3498 42.2201 23.2098 42.2201 31.7998C42.0701 52.9098 54.5901 53.3298 53.1401 82.4298C52.9301 86.6798 49.4601 86.1498 48.8101 91.8598C44.0101 93.0298 31.1601 88.3398 26.3601 89.4998C21.5701 88.3398 9.70011 97.9298 4.91011 96.7598C3.62011 91.1198 2.0501 87.7598 2.0901 83.3198C2.2801 56.3198 13.4801 49.5998 13.4601 33.3198V14.8598C13.4301 -4.14016 42.1901 -3.49016 42.2201 14.7998Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M42.2201 14.7998C42.2201 23.3498 42.2201 23.2098 42.2201 31.7998C42.0701 52.9098 54.5901 53.3298 53.1401 82.4298C52.9301 86.6798 49.4601 86.1498 48.8101 91.8598C44.0101 93.0298 31.1601 88.3398 26.3601 89.4998C21.5701 88.3398 9.70011 97.9298 4.91011 96.7598C3.62011 91.1198 2.0501 87.7598 2.0901 83.3198C2.2801 56.3198 13.4801 49.5998 13.4601 33.3198V14.8598C13.4301 -4.14016 42.1901 -3.49016 42.2201 14.7998Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1898 81.7702C67.5298 82.2602 81.7298 85.8502 81.1098 105.47C81.1098 124.62 81.1898 138.42 81.1098 139.41C81.8998 139.66 41.3598 139.61 41.3598 139.61C41.3598 139.61 41.6498 116.97 41.5198 112.56C41.4098 108.85 41.2298 105.84 35.9698 105.86C35.0398 105.86 23.3298 105.86 22.3498 105.92C14.2698 106.22 0.149791 106.92 0.409791 90.9202C0.409791 88.5202 1.15979 86.1302 2.15979 82.4802C2.18453 82.2375 2.18453 81.9929 2.15979 81.7502H48.6398C51.9398 81.7702 55.9998 81.5902 59.1898 81.7702Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M59.1898 81.7702C67.5298 82.2602 81.7298 85.8502 81.1098 105.47C81.1098 124.62 81.1898 138.42 81.1098 139.41C81.8998 139.66 41.3598 139.61 41.3598 139.61C41.3598 139.61 41.6498 116.97 41.5198 112.56C41.4098 108.85 41.2298 105.84 35.9698 105.86C35.0398 105.86 23.3298 105.86 22.3498 105.92C14.2698 106.22 0.149791 106.92 0.409791 90.9202C0.409791 88.5202 1.15979 86.1302 2.15979 82.4802C2.18453 82.2375 2.18453 81.9929 2.15979 81.7502H48.6398C51.9398 81.7702 55.9998 81.5902 59.1898 81.7702Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.73 36.6899H42.23C42.7604 36.6899 43.2691 36.9007 43.6442 37.2757C44.0193 37.6508 44.23 38.1595 44.23 38.6899C44.23 39.2204 44.0193 39.7291 43.6442 40.1041C43.2691 40.4792 42.7604 40.6899 42.23 40.6899H13.73C13.1995 40.6899 12.6908 40.4792 12.3158 40.1041C11.9407 39.7291 11.73 39.2204 11.73 38.6899C11.73 38.1595 11.9407 37.6508 12.3158 37.2757C12.6908 36.9007 13.1995 36.6899 13.73 36.6899Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M13.73 36.6899H42.23C42.7604 36.6899 43.2691 36.9007 43.6442 37.2757C44.0193 37.6508 44.23 38.1595 44.23 38.6899C44.23 39.2204 44.0193 39.7291 43.6442 40.1041C43.2691 40.4792 42.7604 40.6899 42.23 40.6899H13.73C13.1995 40.6899 12.6908 40.4792 12.3158 40.1041C11.9407 39.7291 11.73 39.2204 11.73 38.6899C11.73 38.1595 11.9407 37.6508 12.3158 37.2757C12.6908 36.9007 13.1995 36.6899 13.73 36.6899Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M13.46 13.1401H57.3"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8598 40.71L43.2998 42.48C37.7416 41.5825 32.1351 41.0148 26.5098 40.78L42.8598 40.71Z"
        fill="#191A1F"
      />
      <path
        d="M42.8598 40.71L43.2998 42.48C37.7416 41.5825 32.1351 41.0148 26.5098 40.78L42.8598 40.71Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.7699 13.0698L42.2099 14.8398C36.6518 13.9415 31.0453 13.3738 25.4199 13.1398L41.7699 13.0698Z"
        fill="#191A1F"
      />
      <path
        d="M41.7699 13.0698L42.2099 14.8398C36.6518 13.9415 31.0453 13.3738 25.4199 13.1398L41.7699 13.0698Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M64.4801 139.74V103.36C64.4801 101.64 65.2601 83.01 48.6401 81.73"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2597 25.6901C40.9303 25.6901 40.6082 25.5926 40.334 25.41C40.0599 25.2273 39.8458 24.9677 39.7189 24.6637C39.5919 24.3597 39.5577 24.025 39.6205 23.7016C39.6833 23.3782 39.8404 23.0806 40.072 22.8463C40.3035 22.612 40.5992 22.4513 40.9218 22.3847C41.2445 22.318 41.5796 22.3482 41.8851 22.4715C42.1906 22.5949 42.4527 22.8058 42.6386 23.0778C42.8245 23.3498 42.9258 23.6707 42.9297 24.0001C42.931 24.2207 42.8889 24.4394 42.8057 24.6438C42.7225 24.8481 42.5998 25.034 42.4448 25.191C42.2897 25.3479 42.1052 25.4728 41.9019 25.5584C41.6986 25.644 41.4804 25.6888 41.2597 25.6901Z"
        fill="white"
      />
      <path
        d="M41.2597 25.6901C40.9303 25.6901 40.6082 25.5926 40.334 25.41C40.0599 25.2273 39.8458 24.9677 39.7189 24.6637C39.5919 24.3597 39.5577 24.025 39.6205 23.7016C39.6833 23.3782 39.8404 23.0806 40.072 22.8463C40.3035 22.612 40.5992 22.4513 40.9218 22.3847C41.2445 22.318 41.5796 22.3482 41.8851 22.4715C42.1906 22.5949 42.4527 22.8058 42.6386 23.0778C42.8245 23.3498 42.9258 23.6707 42.9297 24.0001C42.931 24.2207 42.8889 24.4394 42.8057 24.6438C42.7225 24.8481 42.5998 25.034 42.4448 25.191C42.2897 25.3479 42.1052 25.4728 41.9019 25.5584C41.6986 25.644 41.4804 25.6888 41.2597 25.6901Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2297 22.3501C27.5608 22.3501 27.8845 22.4486 28.1595 22.633C28.4346 22.8174 28.6486 23.0794 28.7744 23.3857C28.9002 23.692 28.9321 24.0288 28.866 24.3533C28.7999 24.6778 28.6389 24.9753 28.4033 25.2081C28.1677 25.4408 27.8683 25.5982 27.5431 25.6604C27.2178 25.7226 26.8814 25.6866 26.5766 25.5572C26.2719 25.4277 26.0124 25.2105 25.8314 24.9333C25.6503 24.656 25.5557 24.3312 25.5597 24.0001C25.565 23.5607 25.7432 23.141 26.0558 22.8321C26.3685 22.5233 26.7902 22.3501 27.2297 22.3501Z"
        fill="white"
      />
      <path
        d="M27.2297 22.3501C27.5608 22.3501 27.8845 22.4486 28.1595 22.633C28.4346 22.8174 28.6486 23.0794 28.7744 23.3857C28.9002 23.692 28.9321 24.0288 28.866 24.3533C28.7999 24.6778 28.6389 24.9753 28.4033 25.2081C28.1677 25.4408 27.8683 25.5982 27.5431 25.6604C27.2178 25.7226 26.8814 25.6866 26.5766 25.5572C26.2719 25.4277 26.0124 25.2105 25.8314 24.9333C25.6503 24.656 25.5557 24.3312 25.5597 24.0001C25.565 23.5607 25.7432 23.141 26.0558 22.8321C26.3685 22.5233 26.7902 22.3501 27.2297 22.3501V22.3501Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.6299 17.5398C41.2887 17.4434 41.9614 17.5479 42.5599 17.8398"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M27.9398 17.5398C27.2809 17.4415 26.6077 17.5461 26.0098 17.8398"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.7601 24.7098C41.8105 24.7098 41.8588 24.7298 41.8945 24.7654C41.9301 24.801 41.9501 24.8494 41.9501 24.8998C41.9477 24.9494 41.9269 24.9963 41.8918 25.0314C41.8567 25.0666 41.8097 25.0873 41.7601 25.0898C41.7097 25.0898 41.6614 25.0697 41.6258 25.0341C41.5901 24.9985 41.5701 24.9502 41.5701 24.8998C41.5687 24.8744 41.5726 24.8491 41.5816 24.8254C41.5907 24.8017 41.6046 24.7801 41.6225 24.7622C41.6405 24.7443 41.662 24.7303 41.6857 24.7213C41.7094 24.7122 41.7348 24.7083 41.7601 24.7098Z"
        fill="#191A1F"
      />
      <path
        d="M41.7601 24.7098C41.8105 24.7098 41.8588 24.7298 41.8945 24.7654C41.9301 24.801 41.9501 24.8494 41.9501 24.8998C41.9477 24.9494 41.9269 24.9963 41.8918 25.0314C41.8567 25.0666 41.8097 25.0873 41.7601 25.0898C41.7097 25.0898 41.6614 25.0697 41.6258 25.0341C41.5901 24.9985 41.5701 24.9502 41.5701 24.8998C41.5687 24.8744 41.5726 24.8491 41.5816 24.8254C41.5907 24.8017 41.6046 24.7801 41.6225 24.7622C41.6405 24.7443 41.662 24.7303 41.6857 24.7213C41.7094 24.7122 41.7348 24.7083 41.7601 24.7098Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8399 24.8998C27.7903 24.8973 27.7434 24.8765 27.7082 24.8414C27.6731 24.8063 27.6523 24.7594 27.6499 24.7098C27.6498 24.6584 27.6695 24.609 27.7049 24.5718C27.7403 24.5345 27.7886 24.5123 27.8399 24.5098C27.8657 24.5097 27.8913 24.515 27.915 24.5251C27.9387 24.5353 27.9601 24.5502 27.9779 24.5689C27.9957 24.5876 28.0095 24.6098 28.0184 24.634C28.0273 24.6582 28.0313 24.684 28.0299 24.7098C28.0299 24.7602 28.0099 24.8085 27.9743 24.8441C27.9386 24.8797 27.8903 24.8998 27.8399 24.8998Z"
        fill="#191A1F"
      />
      <path
        d="M27.8399 24.8998C27.7903 24.8973 27.7434 24.8765 27.7082 24.8414C27.6731 24.8063 27.6523 24.7594 27.6499 24.7098C27.6498 24.6584 27.6695 24.609 27.7049 24.5718C27.7403 24.5345 27.7886 24.5123 27.8399 24.5098C27.8657 24.5097 27.8913 24.515 27.915 24.5251C27.9387 24.5353 27.9601 24.5502 27.9779 24.5689C27.9957 24.5876 28.0095 24.6098 28.0184 24.634C28.0273 24.6582 28.0313 24.684 28.0299 24.7098C28.0299 24.7602 28.0099 24.8085 27.9743 24.8441C27.9386 24.8797 27.8903 24.8998 27.8399 24.8998V24.8998Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8701 28.8799C30.2601 29.8199 31.3501 29.9399 32.2501 29.9399"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.58 43.2098L53.1 48.7098L45.52 75.7098L28.52 69.7098L35.69 44.1798C35.74 43.9854 35.8306 43.8038 35.9559 43.6469C36.0812 43.4901 36.2384 43.3616 36.417 43.27C36.5956 43.1783 36.7916 43.1255 36.992 43.1152C37.1925 43.1048 37.3929 43.1371 37.58 43.2098Z"
        fill="#191A1F"
      />
      <path
        d="M37.58 43.2098L53.1 48.7098L45.52 75.7098L28.52 69.7098L35.69 44.1798C35.74 43.9854 35.8306 43.8038 35.9559 43.6469C36.0812 43.4901 36.2384 43.3616 36.417 43.27C36.5956 43.1783 36.7916 43.1255 36.992 43.1152C37.1925 43.1048 37.3929 43.1371 37.58 43.2098V43.2098Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.0002 43.6402L53.2802 48.6402L45.7002 75.6402L52.5602 69.6402L59.3902 45.3302C59.8402 43.8402 59.6602 43.0902 59.0002 43.6402Z"
        fill="#191A1F"
      />
      <path
        d="M59.0002 43.6402L53.2802 48.6402L45.7002 75.6402L52.5602 69.6402L59.3902 45.3302C59.8402 43.8402 59.6602 43.0902 59.0002 43.6402Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7501 47.2001C22.2601 52.1401 22.9101 57.1101 27.5001 61.3901C28.3601 62.1901 34.4001 63.9602 38.9701 65.2702C40.9101 65.8202 44.5201 64.6601 45.4201 64.9401C46.3201 65.2201 47.9001 66.0401 46.6101 68.3701C46.3601 68.8301 49.7401 68.5001 50.3801 69.1801C52.1701 71.1101 49.3801 72.5501 49.3801 72.5501C49.5301 74.0702 48.4901 74.6501 46.9401 75.2501C46.8206 75.6172 46.5975 75.9419 46.2976 76.185C45.9978 76.4281 45.6339 76.5792 45.2501 76.6201C41.2226 77.0206 37.1558 76.6817 33.2501 75.6201C31.8401 75.2301 30.3501 74.7602 28.9301 74.2702C21.6601 71.7602 15.9301 70.2702 12.4101 62.9602C12.1001 62.3202 10.5601 59.2201 9.41007 57.0501C6.41007 51.2501 16.1301 42.9601 18.7201 47.1501"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M18.7502 47.2002C22.2602 52.1402 22.9102 57.1102 27.5002 61.3902C28.3602 62.1902 34.4002 63.9602 38.9702 65.2702C40.9102 65.8202 44.5202 64.6602 45.4202 64.9402C46.3202 65.2202 47.9002 66.0402 46.6102 68.3702C46.3602 68.8302 49.7402 68.5002 50.3802 69.1802C52.1702 71.1102 49.3801 72.5502 49.3801 72.5502C49.5301 74.0702 48.4902 74.6502 46.9402 75.2502C46.8207 75.6173 46.5976 75.942 46.2977 76.1851C45.9978 76.4282 45.634 76.5793 45.2502 76.6202C41.2226 77.0207 37.1558 76.6818 33.2502 75.6202C31.8402 75.2302 30.3502 74.7602 28.9302 74.2702C21.6602 71.7602 15.9302 70.2702 12.4102 62.9602C12.1002 62.3202 10.5602 59.2202 9.41016 57.0502"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M45 72C46.5078 72.4243 48.0778 72.5833 49.64 72.47"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M42.5 74.6899C44.0043 75.1142 45.5711 75.2733 47.13 75.1599"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0003 77.84C31.1103 77.02 21.8703 72.56 18.0003 69.84C17.4403 69.44 15.8103 67.96 17.9303 69.58C20.0503 71.2 23.9303 72.58 26.9303 73.66C32.2806 75.6464 37.9335 76.6951 43.6403 76.76C43.6403 76.76 42.4103 78.19 39.0003 77.84Z"
        fill="#191A1F"
      />
      <path
        d="M39.0003 77.84C31.1103 77.02 21.8703 72.56 18.0003 69.84C17.4403 69.44 15.8103 67.96 17.9303 69.58C20.0503 71.2 23.9303 72.58 26.9303 73.66C32.2806 75.6464 37.9335 76.6951 43.6403 76.76C43.6403 76.76 42.4103 78.19 39.0003 77.84Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M46.9598 68.6199L43.0098 68.1299"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19425_3751">
        <rect width="92" height="157" fill="white" />
      </clipPath>
    </defs>
  </ChubbsWrapper>
));

import React, { type FC } from 'react';
import { cn } from '@/lib/classNames';
import { DEFAULT_PROGRESS_BAR_SEGMENTS_COUNT } from '@/components/platform/common/ProgressBarHorizontal/constants';
import { colorTokens } from '@/components/ui/colorTokens';
import styles from './ProgressBarHorizontal.module.scss';

interface ChangedProgressData {
  updatedProgress: number;
  increasedProgressColor: string;
  decreasedProgressColor: string;
}

interface Props {
  maxValue?: number;
  progress: number;
  changedProgressData?: ChangedProgressData;
  mode?: ProgressBarMode;
  pathColor?: string;
  trailColor?: string;
  emptyColor?: string;
  className?: string;
  size?: ProgressBarSize;
}

export enum ProgressBarMode {
  Dashed = 'dashed',
  Solid = 'solid',
  SolidWithoutBars = 'solidWithoutBars',
}

export enum ProgressBarSize {
  Small = 'small',
  Medium = 'medium',
}

const modeClassNames: Partial<Record<ProgressBarMode, string>> = {
  [ProgressBarMode.Dashed]: styles.dashed,
  [ProgressBarMode.SolidWithoutBars]: 'empty',
};

const getItemColor = (options: {
  index: number;
  progress: number;
  changedProgressData?: ChangedProgressData;
  pathColor: string;
  trailColor: string;
}) => {
  const {
    index,
    progress,
    pathColor,
    trailColor,
    changedProgressData,
  } = options;

  if (
    !changedProgressData
    || changedProgressData.updatedProgress === progress
  ) {
    return index < progress
      ? pathColor
      : trailColor;
  }

  const {
    updatedProgress,
    increasedProgressColor,
    decreasedProgressColor,
  } = changedProgressData;

  const isProgressIncreased = progress < updatedProgress;
  const changedProgressColor = isProgressIncreased
    ? increasedProgressColor
    : decreasedProgressColor;

  if (index < Math.min(progress, updatedProgress)) {
    return pathColor;
  }

  if (index < Math.max(progress, updatedProgress)) {
    return changedProgressColor;
  }

  return trailColor;
};

export const ProgressBarHorizontal: FC<Props> = (props) => {
  const {
    progress,
    maxValue = DEFAULT_PROGRESS_BAR_SEGMENTS_COUNT,
    mode = ProgressBarMode.Solid,
    pathColor = colorTokens['text-secondary'],
    trailColor = colorTokens['bg-neutral-quaternary'],
    emptyColor = colorTokens['bg-neutral-quaternary'],
    className,
    changedProgressData,
    size = ProgressBarSize.Medium,
  } = props;

  if (mode === ProgressBarMode.SolidWithoutBars) {
    return (
      <div
        className={
          cn(
            styles.progressBarWrapper,
            styles[`progressBarWrapper--${size}`],
          )
        }
        style={{
          backgroundColor: emptyColor,
        }}
        data-qa='progress-bar'
      >
        <div
          className={cn(styles.progressBarSolid)}
          style={{
            width: `${progress * 10}%`,
            backgroundColor: pathColor,
          }}
          data-qa='module-progress-bar'
        />
      </div>
    );
  }

  return (
    <div
      className={cn(
        styles.progressBarWrapper,
        styles[`progressBarWrapper--${size}`],
      )}
      data-qa='progress-bar'
    >
      {[...Array(maxValue).keys()].map((item, i) => {
        const itemColor = getItemColor({
          index: i,
          progress,
          changedProgressData,
          pathColor,
          trailColor,
        });
        const color = itemColor.replace(')', '').split('-').pop();

        return (
          <div
            data-qa={
              progress === 0
                ? 'progress-bar-item-filled'
                : `progress-bar-item-${color}`
            }
            key={item}
            className={cn(
              styles.progressBarItem,
              modeClassNames[mode],
              className,
            )}
            style={{
              backgroundColor: progress === 0
                ? emptyColor
                : itemColor,
            }}
          />
        );
      })}
    </div>
  );
};

import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  ChubbsPhoneLight,
} from '@/components/ui/Chubbs/ChubbsPhone/ChubbsPhone.light';
import {
  ChubbsPhoneDark,
} from '@/components/ui/Chubbs/ChubbsPhone/ChubbsPhone.dark';

export const ChubbsPhone: FC<ChubbsProps> = memo((props) => (
  <Chubbs
    LightChubbs={ChubbsPhoneLight}
    DarkChubbs={ChubbsPhoneDark}
    {...props}
  />
));
